import React, {Component} from "react";
import './FilterDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterConditionItem from "./FilterConditionItem/FilterConditionItem";
import DialogActions from "@mui/material/DialogActions";

class FilterDialog extends Component {

    state = {
        filter:this.props.filter
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({filter:this.props.filter});
        }
    }

    togglePeriodAsDate = () => {
        let filter = this.state.filter;
        filter.periodAsDate = !filter.periodAsDate;
        this.setState({filter:filter});
    }

    addConditionOnClickHandler = () => {
        let filter = this.state.filter;
        if(!filter.filterConditions) {
            filter.filterConditions = [];
        }
        filter.filterConditions.push({});
        this.setState({filter:filter});
    }

    nameOnChangeHandler = (e) => {
        let filter = this.state.filter;
        filter.name = e.target.value;
        this.setState({filter:filter});
    }

    fromDateOnChangeHandler = (e) => {
        let filter = this.state.filter;
        filter.fromDate = e.target.value;
        this.setState({filter:filter});
    }

    toDateOnChangeHandler = (e) => {
        let filter = this.state.filter;
        filter.toDate = e.target.value;
        this.setState({filter:filter});
    }

    fromDaysOnChangeHandler = (e) => {
        let filter = this.state.filter;
        filter.fromDays = e.target.value;
        this.setState({filter:filter});
    }

    toDaysOnChangeHandler = (e) => {
        let filter = this.state.filter;
        filter.toDays = e.target.value;
        this.setState({filter:filter});
    }

    conditionFilterKindOnChangeHandler = (e,index) => {
        let filter = this.state.filter;
        filter.filterConditions[index].filterKind = e.target.value;
        filter.filterConditions[index].state = e.target.value;
        filter.filterConditions[index].commissionNr = null;
        filter.filterConditions[index].commissionNrOperator = null;
        filter.filterConditions[index].article = null;
        filter.filterConditions[index].supplier = null;
        filter.filterConditions[index].customer = null;
        this.setState({filter:filter});
    }

    conditionOrderStateOnChangeHandler = (e,index) => {
        let filter = this.state.filter;
        filter.filterConditions[index].orderState = e.target.value;
        this.setState({filter:filter});
    }

    conditionArticleOnChangeHandler = (article,index) => {
        let filter = this.state.filter;
        filter.filterConditions[index].article = article;
        this.setState({filter:filter});
    }

    conditionCustomerOnChangeHandler = (customer,index) => {
        let filter = this.state.filter;
        filter.filterConditions[index].customer = customer;
        this.setState({filter:filter});
    }

    conditionSupplierOnChangeHandler = (supplier,index) => {
        let filter = this.state.filter;
        filter.filterConditions[index].supplier = supplier;
        this.setState({filter:filter});
    }

    conditionCommissionNrOperatorOnChangeHandler = (e,index) => {
        let filter = this.state.filter;
        filter.filterConditions[index].operator = e.target.value;
        this.setState({filter:filter});
    }

    conditionCommissionNrOnChangeHandler = (e,index) => {
        let filter = this.state.filter;
        filter.filterConditions[index].commissionNr = e.target.value;
        this.setState({filter:filter});
    }

    removeConditionOnClickHandler = (index) => {
        let filter = this.state.filter;
        filter.filterConditions.splice(index,1);
        this.setState({filter:filter});
    }

    render() {

        let filterTimeType = "";

        if(this.state.filter && this.state.filter.periodAsDate) {
            filterTimeType = "Zeitspanne als Datum festlegen";
        }else if(this.state.filter && !this.state.filter.periodAsDate){
            filterTimeType = "Zeitspanne in Tagen festlegen";
        }

        return (
            <Dialog scroll='body' maxWidth={"lg"} open={this.props.open} onClose={this.props.close} className='filter-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Filter</DialogTitle>
                    <TextField size="small"
                               value={this.state.filter && this.state.filter.name ? this.state.filter.name : null}
                               onChange={this.nameOnChangeHandler} className='field' label='Filtername'
                               placeholder="z.B. Dringende Bestellungen"/>
                    <div>
                        <FormControlLabel
                            className='switch-label'
                            control={
                                <Switch className='switch' checked={this.state.filter ? this.state.filter.periodAsDate : false}
                                        onChange={this.togglePeriodAsDate} color="primary"/>
                            }
                            label={filterTimeType}
                        />
                        {this.state.filter && this.state.filter.periodAsDate ? <div>
                            <TextField size="small"
                                       value={this.state.filter && this.state.filter.fromDate ? this.state.filter.fromDate : null}
                                       onChange={this.fromDateOnChangeHandler} type="date" className='field'
                                       InputLabelProps={{shrink: true}}
                                       label='Von (Datum)'/>
                            <TextField size="small"
                                       value={this.state.filter && this.state.filter.toDate ? this.state.filter.toDate : null}
                                       onChange={this.toDateOnChangeHandler} type="date" className='field'
                                       InputLabelProps={{shrink: true}}
                                       label='Bis (Datum)'/>
                        </div> : <div>
                            <TextField size="small"
                                       value={this.state.filter && this.state.filter.fromDays !== null ? this.state.filter.fromDays : null}
                                       onChange={this.fromDaysOnChangeHandler} type="number" className='field'
                                       label='Von (Tage in der Vergangenheit)'/>
                            <TextField size="small"
                                       value={this.state.filter && this.state.filter.toDays !== null ? this.state.filter.toDays : null}
                                       onChange={this.toDaysOnChangeHandler} type="number" className='field'
                                       label='Bis (Tage in der Vergangenheit)'/>
                        </div>}
                    </div>
                    <div>
                        {this.state.filter && this.state.filter.filterConditions ? this.state.filter.filterConditions.map((condition, index) => (
                            <FilterConditionItem
                                kind={this.props.kind}
                                key={index}
                                index={index}
                                condition={condition}
                                conditionFilterKindOnChangeHandler={this.conditionFilterKindOnChangeHandler}
                                conditionOrderStateOnChangeHandler={this.conditionOrderStateOnChangeHandler}
                                articles={this.props.articles}
                                customers={this.props.customers}
                                suppliers={this.props.suppliers}
                                conditionArticleOnChangeHandler={this.conditionArticleOnChangeHandler}
                                conditionCustomerOnChangeHandler={this.conditionCustomerOnChangeHandler}
                                conditionSupplierOnChangeHandler={this.conditionSupplierOnChangeHandler}
                                conditionCommissionNrOperatorOnChangeHandler={this.conditionCommissionNrOperatorOnChangeHandler}
                                conditionCommissionNrOnChangeHandler={this.conditionCommissionNrOnChangeHandler}
                                removeConditionOnClickHandler={this.removeConditionOnClickHandler}
                            />
                        )) : null}
                    </div>
                    <ToolButton className='add-condition-button' onClick={this.addConditionOnClickHandler}>
                        Bedingung hinzufügen
                    </ToolButton>
                    <DialogActions>
                        <ToolButton main className='button' onClick={() => this.props.saveFilterOnClickHandler(this.state.filter)}>
                            Speichern
                        </ToolButton>
                    </DialogActions>
                </div>
            </Dialog>
        )
    }
}

export default FilterDialog;