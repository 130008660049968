import React, { useState, useEffect, useRef } from 'react';
import './PinDialog.css';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {Tooltip} from "react-tooltip";
import api from "../../../../../../../api";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";

//Components

const PinDialog = (props) => {

    const [pin,setPin] = useState(null);

    const pinOnChangeHandler = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin(value.slice(0,4));
    }

    const savePinOnClickHandler = () => {
        if(pin && pin.length === 4) {
            savePin();
        }else{
            props.showMessage(2,"Die Pin muss 4 Stellen haben");
        }
    }

    const savePin = () => {
        api.put("/users/pin",pin, {
            headers: { "Content-Type": "text/plain" }, // Ensure the backend expects a raw string
        })
            .then(response => {
                props.setUser(response.data);
                props.showMessage(0,"Der PIN wurde erfolgreich gesetzt")
                close();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const close = () => {
        setPin(null);
        props.close();
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={close} className='pin-dialog'>
            <Tooltip id="search-transaction-dialog-tooltip"/>

            <DialogContent>
                <CloseIcon onClick={close} className="icon close-icon"/>
                <DialogTitle>PIN setzen</DialogTitle>
                <p>Bitte geben Sie Ihre PIN für den vereinfachten Login ein</p>
                <TextField
                    className="field"
                    value={pin}
                    onChange={pinOnChangeHandler}
                    type="password"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    autoComplete="off"
                    placeholder="PIN"
                />
                <DialogActions>
                    <ToolButton main className='button' onClick={savePinOnClickHandler}>
                        Speichern
                    </ToolButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default PinDialog;