import React, {Component, useEffect, useState} from "react";
import './ReportDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import ArticleReport from "./ArticleReport/ArticleReport";
import ReportingDataChart from "../ReportingDataChart/ReportingDataChart";
import HelperFunctions from "../../../../../../../reusable/HelperFunctions";

const ReportDialog = (props) => {

    const [formattedTransactions, setFormattedTransactions] = useState(null);

    useEffect(() => {
        formatTransactions(props.reportResult)
    }, []);

    const formatTransactions = (reportResult) => {
        let formattedTransactions = [];
        const fromDate = new Date(props.reportRequest.fromDate);
        const toDate = new Date(props.reportRequest.toDate);
        const difference = toDate - fromDate + 1;
        const differenceDays = difference / (1000 * 60 * 60 * 24); // convert to days
        for(let i = 0; i < differenceDays; i++) {
            let sum = 0;
            for(let j = 0; j < reportResult.transactions.length; j++) {
                if(reportResult.transactions[j].dateTime && reportResult.transactions[j].dateTime.substring(0,10) === toDate.toISOString().substring(0,10)) {
                    sum++;
                }
            }
            let todayData = {
                "Datum":HelperFunctions.formatDate(toDate.toISOString()),
                "Transaktionen":sum
            }
            formattedTransactions.unshift(todayData);
            toDate.setDate(toDate.getDate()-1);
        }
        setFormattedTransactions(mergeData(formattedTransactions));
    }

    const mergeData = (transactions) => {
        const mergedData = {};

        // Helper function to add data
        const addData = (source, key) => {
            source.forEach(entry => {
                const date = entry.Datum;
                if (!mergedData[date]) {
                    mergedData[date] = { Datum: date, "Transaktionen": 0};
                }
                mergedData[date][key] = entry[key] || 0;
            });
        };

        // Merge all data sources
        addData(transactions, "Transaktionen");

        // Convert object back to array
        return Object.values(mergedData).sort((a, b) => new Date(a.Datum) - new Date(b.Datum));
    }

    return (
        <Dialog scroll='body' maxWidth={"xl"} open={props.open} onClose={props.close} className='report-dialog'>
            {props.reportResult ?
                <div className='surrounder'>
                    <DialogTitle>Ergebnis</DialogTitle>
                    <DialogContent>
                        {props.reportResult ? <ReportingDataChart
                            formattedTransactions={formattedTransactions}
                        /> : null}
                        <p className="info-text">Summe Transaktionen: {props.reportResult.transactions.length}</p>
                        {props.reportResult && props.reportResult.articleReports ? props.reportResult.articleReports.map(articleReport => (
                            <ArticleReport
                                articleReport={articleReport}
                            />
                        )) : null}
                    </DialogContent>
                </div> :
                <div className='surrounder'>
                    <DialogTitle>Ergebnis</DialogTitle>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </div>
            }
        </Dialog>
    )
}

export default ReportDialog;