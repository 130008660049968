import React, {useEffect, useState} from "react";
import './Login.css';

//Components
import ToolButton from "../../reusable/ToolButton/ToolButton";
import logo from './../../images/logo.png';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import ToolPaper from "../../reusable/ToolPaper/ToolPaper";

const Login = (props) => {

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            props.loginClick();
        }
    }

    useEffect(() => {
        props.loadFastLogins();
    }, []);

    return (
        <div onKeyDown={_handleKeyDown} className='login primary-color-background-very-light'>
            <ToolPaper className='login-div white-background'>
                <img className='wm-logo' src={logo} alt="Warehouse Manager 4.0 Logo"/>
                <div className='input-div box-shadow-dark'>
                    <BusinessIcon className='input-icon'/>
                    <input className='input-field' placeholder='Unternehmenskürzel (5-stellig)'
                           onChange={props.loginAbbreviationChangeHandler}
                           defaultValue={localStorage.getItem('abbreviation')}/><br/>
                </div>
                <div className='input-div box-shadow-dark'>
                    <PersonIcon className='input-icon'/>
                    <input className='input-field' placeholder='Benutzername'
                           onChange={props.loginUsernameChangeHandler}/><br/>
                </div>
                <div className='input-div box-shadow-dark'>
                    <LockIcon className='input-icon'/>
                    <input className='input-field' placeholder='Passwort' type='password'
                           onChange={props.loginPasswordChangeHandler}/><br/>
                </div>
                <a href='https://www.panthera-software.com/loesungen/warehouse-manager#call-to-action'
                   target='_blank' rel='noopener noreferrer' className='register-text'>Unternehmensaccount
                    registrieren</a>
                <ToolButton onClick={props.loginClick} main className='login-button'>Anmelden</ToolButton>
            </ToolPaper>
            {props.fastLogins && props.fastLogins.length > 0 ? <ToolPaper className="fast-login-div white-background">
                <h2>Schnelllogins</h2>
                {props.fastLogins.map(fastLogin => (
                    <ToolPaper onClick={() => props.showFastLoginDialog(fastLogin)} className="fast-login-item animate">
                        <p>{fastLogin.username}</p>
                    </ToolPaper>
                ))}
            </ToolPaper> : null}
            <a className="company-website" href="https://www.panthera-software.com" target="_blank"
               rel="noopener noreferrer">2025 Panthera Software Solutions GmbH</a>
        </div>
    )
}

export default Login;