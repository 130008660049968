import React, {useEffect, useRef, useState} from "react";
import './FastLoginDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../ToolButton/ToolButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

const FastLoginDialog = (props) => {

    const [pin,setPin] = useState(null);

    const pinRef = useRef(null);

    useEffect(() => {
        if(pinRef && pinRef.current) {
            pinRef.current.focus();
        }
    }, []);

    const pinOnChangeHandler = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin(value.slice(0,4));
    }

    const fastLoginOnClickHandler = () => {
        if(pin && pin.length === 4) {
            let updatedFastLoginRequest = props.fastLoginRequest;
            updatedFastLoginRequest.pin = pin;
            props.fastLogin(updatedFastLoginRequest);
        }else{
            props.showMessage(2,"Die Pin muss 4 Stellen haben");
        }
    }

    const close = () => {
        setPin(null);
        props.close();
    }

    return (
        <Dialog scroll='body' open={props.open} onClose={props.close} className='fast-login-dialog'>
            <CloseIcon onClick={close} className="icon close-icon"/>
            <DialogTitle>PIN eingeben</DialogTitle>
            <DialogContent>
                <p>Geben Sie Ihre PIN ein</p>
                <TextField
                    className="field"
                    value={pin}
                    onChange={pinOnChangeHandler}
                    type="password"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    autoComplete="off"
                    placeholder="PIN"
                    inputRef={pinRef}
                />
                <DialogActions>
                    <ToolButton main className='button' onClick={fastLoginOnClickHandler}>
                        Anmelden
                    </ToolButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default FastLoginDialog;