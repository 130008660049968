import React, { useState, useEffect, useRef } from 'react';
import './CustomerTransactionsDialog.css';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import HelperFunctions from "../../../../../../../../reusable/HelperFunctions";
import {createFilterOptions} from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TransactionItem from "../../../../../../../../reusable/TransactionItem/TransactionItem";
import api from "../../../../../../../../api";
import SecureYesCancelDialog from "../../../../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";
import * as XLSX from 'xlsx';
import {Tooltip} from "react-tooltip";
import TransactionDialog from "../../../../../../../../reusable/TransactionDialog/TransactionDialog";
import CircularProgress from "@mui/material/CircularProgress";

//Components

const CustomerTransactionsDialog = (props) => {

    const [activeTransaction,setActiveTransaction] = useState(null);
    const [deleteTransactionDialog,setDeleteTransactionDialog] = useState(false);
    const [transactionDialog, setTransactionDialog] = useState(false);
    const [customerTransactions, setCustomerTransactions] = useState(null);

    useEffect(() => {
        if(props.open) {
            loadCustomerTransactions(props.customer)
        }
    }, [props.open]);

    const deleteTransaction = (transaction) => {
        api.delete("/transactions/" + transaction.id)
            .then(response => {
                setActiveTransaction(null)
                removeTransactionFromHistory(transaction);
                props.showMessage(0,"Transaktion erfolgreich gelöscht!");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const removeTransactionFromHistory = (transaction) => {
        let transactions = [ ...customerTransactions ];
        for(let i = 0; i < transactions.length; i++) {
            if (transactions[i].id === transaction.id) {
                transactions.splice(i,1)
                setCustomerTransactions(transactions);
                return;
            }
        }
    }

    const updateTransactionInList = (transaction) => {
        let updatedTransactions = customerTransactions;
        for(let i = 0; i < updatedTransactions.length; i++) {
            if(updatedTransactions[i].id === transaction.id) {
                updatedTransactions[i] = transaction;
                break;
            }
        }
        setCustomerTransactions(updatedTransactions);
        props.updateTransactionInList(transaction);
    }

    const loadCustomerTransactions = (customer) => {
        api.get("/transactions/customer/" + customer.id)
            .then(response => {
                setCustomerTransactions(response.data);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const showDeleteTransactionDialog = (e,transaction) => {
        e.stopPropagation();
        setActiveTransaction(transaction);
        setDeleteTransactionDialog(true);
    }

    const closeDeleteTransactionDialog = () => {
        setDeleteTransactionDialog(false);
        setActiveTransaction(null);
    }

    const showTransactionDialog = (transaction) => {
        setActiveTransaction(transaction);
        setTransactionDialog(true);
    }

    const closeTransactionDialog = () => {
        setTransactionDialog(false);
        setActiveTransaction(null);
    }

    const close = () => {
        setCustomerTransactions(null);
        props.close();
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={close} className='customer-transactions-dialog'>
            <Tooltip id="customer-history-dialog-tooltip"/>

            <SecureYesCancelDialog
                open={deleteTransactionDialog}
                close={closeDeleteTransactionDialog}
                header='Löschen'
                text='Wollen Sie die Transaktion wirklich löschen?'
                onClick={() => deleteTransaction(activeTransaction)}
                showMessage={props.showMessage}
            />

            {activeTransaction ? <TransactionDialog
                open={transactionDialog}
                close={closeTransactionDialog}
                transaction={activeTransaction}
                showMessage={props.showMessage}
                setLoading={props.setLoading}
                updateTransactionInList={updateTransactionInList}
                displayArticleInformation={props.displayArticleInformation}
                displayBatchInformation={props.displayBatchInformation}
            /> : null}

            <DialogContent>
                <CloseIcon onClick={props.close} className="icon close-icon"/>
                <DialogTitle>Kundentransaktionen</DialogTitle>

                <div className="transaction-history">
                    {customerTransactions !== null ? customerTransactions.length > 0 ? customerTransactions.slice().reverse().map((transaction, index) => (
                        <TransactionItem
                            key={transaction.id}
                            index={index}
                            transaction={transaction}
                            showTransactionDialog={showTransactionDialog}
                            showDeleteTransactionDialog={showDeleteTransactionDialog}
                        />
                    )) : <p>Diesem Kunde wurden noch keine Transaktionen zugewiesen</p> : <CircularProgress className="icon history-icon"/>}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default CustomerTransactionsDialog;