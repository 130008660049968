import React, {Component} from "react";
import './Reporting.css';

//Components
import TextField from "@mui/material/TextField";
import ConditionItem from "./ConditionItem/ConditionItem";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import api from "../../../../../../api";
import ReportDialog from "./ReportDialog/ReportDialog";
import ReportingInfoDialog from "./ReportingInfoDialog/ReportingInfoDialog";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from 'react-tooltip'
import ToolTextField from "../../../../../../reusable/ToolField/ToolTextField";

class Reporting extends Component {

    state = {
        reportRequest: {
            fromDate:'',
            toDate:'',
            conditions:[]
        },
        reportResult:null,
        formattedResult:null,

        // Dialog
        showReportDialog:false,
        showReportInfoDialog:false
    }

    //Handler

    fromDateOnChangeHandler = (e) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.fromDate = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    toDateOnChangeHandler = (e) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.toDate = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    addConditionOnClickHandler = () => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions.push({
            type:0,
            article: {
                nr:''
            },
            customer:null,
            supplier:null,
            attributeValue: {
                attribute:null,
                booleanValue:null,
                numberValue:'',
                stringValue:''
            },
            operator:null
        });
        this.setState({reportRequest:reportRequest});
    }

    removeConditionOnClickHandler = (index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions.splice(index,1);
        this.setState({reportRequest:reportRequest});
    }

    reportRequestOnClickHandler = () => {
        if(this.state.reportRequest.fromDate !== '' && this.state.reportRequest.toDate !== '') {
            this.sendReportRequest(this.state.reportRequest);
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    sendReportRequest = (reportRequest) => {
        this.props.setLoading(true);
        const url = '/report';
        api.post(url,reportRequest)
            .then(response => {
                let allTransactions = response.data;
                let articleReports = [];
                for(let i = 0; i < allTransactions.length; i++) {
                    let transaction = allTransactions[i];
                    let exists = false;
                    for(let j = 0; j < articleReports.length; j++) {
                        if(transaction.article.id === articleReports[j].article.id) {
                            let updatedArticleReport = articleReports[j];
                            if(transaction.inventoryCount < 0) {
                                updatedArticleReport.inventoryCountOut = updatedArticleReport.inventoryCountOut + transaction.inventoryCount;
                                updatedArticleReport.sumProfit = updatedArticleReport.sumProfit + transaction.netPrice;
                            }else{
                                updatedArticleReport.inventoryCountIn = updatedArticleReport.inventoryCountIn + transaction.inventoryCount;
                                updatedArticleReport.sumCosts = updatedArticleReport.sumCosts + transaction.netPrice;
                            }
                            updatedArticleReport.transactions.push(transaction);
                            articleReports[j] = updatedArticleReport;
                            exists = true;
                            break;
                        }
                    }
                    if(!exists) {
                        let articleReport = {
                            article: transaction.article,
                            inventoryCountOut: transaction.inventoryCount < 0 ? transaction.inventoryCount : 0,
                            sumProfit: transaction.inventoryCount < 0 ? transaction.netPrice : 0,
                            inventoryCountIn: transaction.inventoryCount >= 0 ? transaction.inventoryCount : 0,
                            sumCosts: transaction >= 0 ? transaction.netPrice : 0,
                            transactions: [
                                transaction
                            ]
                        }
                        articleReports.push(articleReport);
                    }
                }
                let reportResult = {
                    articleReports: articleReports,
                    transactions: allTransactions
                }
                console.log(reportResult);
                this.setState({reportResult:reportResult,showReportDialog:true});
                this.props.setLoading(false);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error.message);
                this.props.setLoading(false);
            });
    }

    //Condition Handler

    typeOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].type = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    articleNrOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].article.nr = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    customerOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].customer = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    supplierOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].supplier = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    attributeOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].attributeValue.attribute = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    userOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].user = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    booleanValueOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].attributeValue.booleanValue = e.target.checked;
        this.setState({reportRequest:reportRequest});
    }

    numberValueOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].attributeValue.numberValue = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    operatorOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].operator = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    stringValueOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.conditions[index].attributeValue.stringValue = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    // Dialog actions

    showReportDialog = () => {
        this.setState({showReportDialog:true});
    }

    closeReportDialog = () => {
        this.setState({showReportDialog:false});
    }

    showReportInfoDialog = () => {
        this.setState({showReportInfoDialog:true});
    }

    closeReportInfoDialog = () => {
        this.setState({showReportInfoDialog:false});
    }

    render() {
        return (
            <div className='reporting'>
                <Tooltip id="reporting-tooltip"/>
                <InfoIcon onClick={this.showReportInfoDialog} data-tooltip-id="reporting-tooltip" data-tooltip-place="top" data-tooltip-content='Reporting Info' className='icon info-icon'/>

                {this.state.showReportDialog && this.state.reportRequest ? <ReportDialog
                    open={this.state.showReportDialog}
                    close={this.closeReportDialog}
                    reportRequest={this.state.reportRequest}
                    reportResult={this.state.reportResult}
                /> : null}

                <ReportingInfoDialog
                    open={this.state.showReportInfoDialog}
                    close={this.closeReportInfoDialog}
                />

                <h1>Reporting</h1>
                <ToolTextField size="small" className='date-input' type='date' label='von (inklusive)' onChange={this.fromDateOnChangeHandler}  InputLabelProps={{shrink: true}}/>
                <ToolTextField size="small" className='date-input' type='date' label='bis (inklusive)' onChange={this.toDateOnChangeHandler}  InputLabelProps={{shrink: true}}/>
                <ToolButton className='add-attribute-button' onClick={this.addConditionOnClickHandler}>Bedingung hinzufügen</ToolButton>
                <div className='condition-list'>
                    {this.state.reportRequest.conditions.map((condition, index) => (
                        <ConditionItem
                            key={index}
                            index={index}
                            condition={condition}

                            attributes={this.props.attributes}
                            customers={this.props.customers}
                            suppliers={this.props.suppliers}
                            users={this.props.users}

                            removeConditionOnClickHandler={this.removeConditionOnClickHandler}
                            typeOnChangeHandler={this.typeOnChangeHandler}

                            articleNrOnChangeHandler={this.articleNrOnChangeHandler}
                            customerOnChangeHandler={this.customerOnChangeHandler}
                            supplierOnChangeHandler={this.supplierOnChangeHandler}
                            attributeOnChangeHandler={this.attributeOnChangeHandler}
                            userOnChangeHandler={this.userOnChangeHandler}

                            booleanValueOnChangeHandler={this.booleanValueOnChangeHandler}
                            numberValueOnChangeHandler={this.numberValueOnChangeHandler}
                            operatorOnChangeHandler={this.operatorOnChangeHandler}
                            stringValueOnChangeHandler={this.stringValueOnChangeHandler}
                        />
                    ))}
                </div>
                <ToolButton main className='report-button' onClick={this.reportRequestOnClickHandler}>Report generieren</ToolButton>
            </div>
        )
    }
}

export default Reporting;