import React, {Component} from 'react';
import './App.css';

//Components
import Tool from './components/Tool/Tool';
import Login from './components/Login/Login';
import Footer from "./components/Footer/Footer";
import api from './api';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router} from "react-router-dom";
import LoadingBackdrop from "./reusable/LoadingBackdrop/LoadingBackdrop";
import ExpiredPasswordDialog from "./reusable/ExpiredPasswordDialog/ExpiredPasswordDialog";
import FastLoginDialog from "./reusable/FastLoginDialog/FastLoginDialog";
import HelperFunctions from "./reusable/HelperFunctions";

toast.configure();

class App extends Component {

    state = {
        loggedIn: localStorage.getItem('token')!== null,
        usernameInput: '',
        passwordInput: '',
        newPasswordInput: '',
        abbreviationInput: localStorage.getItem('abbreviation'),
        fastLogin:null,
        fastLogins:null,
        username:localStorage.getItem('username'),
        loading:false,
        showExpiredPasswordDialog:false
    };

    componentDidMount() {
        this.registerUnloadListener();
    }

    loadFastLogins = () => {
        let abbreviation = localStorage.getItem("abbreviation");
        if(abbreviation) {
            let deviceToken = localStorage.getItem("deviceToken");
            let today = new Date().getDate();
            if(!deviceToken || deviceToken.substring(0,2) !== today.toString()) {
                deviceToken = today + HelperFunctions.generateRandomString(16);
                localStorage.setItem("deviceToken",deviceToken);
            }
            const data = {
                abbreviation:this.state.abbreviationInput,
                deviceToken:deviceToken
            };
            api.post("/auth/fast-logins",data)
                .then(response => {
                    console.log(response.data)
                    this.setState({fastLogins:response.data});
                })
                .catch(error => {
                    if(error.response.data === "Die Zugangsdaten sind abgelaufen") {
                        this.showExpiredPasswordDialog();
                    }else if(error.response) {
                        this.showMessage(2,"Unternehmenskürzel, Benutzername und Passwort stimmen nicht über ein");
                        console.log(error.response)
                    } else if (error.request) {
                        this.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                        console.log(error.request);
                    } else {
                        this.showMessage(2,"Etwas ist schiefgelaufen");
                        console.log(error.message);
                    }
                });
        }
    }

    setLoading = (value) => {
        this.setState({loading:value});
    }

    registerUnloadListener = () => {
        window.onbeforeunload = (event) => {
            const url = "/users/logout";
            return api.put(url);
        }
    };

    //General actions

    showMessage = (kind,message) => {
        if(kind === 0) {
            toast.success(message,{position:toast.POSITION.BOTTOM_CENTER,autoClose:3000});
        }else if(kind === 1) {
            toast.warning(message,{position:toast.POSITION.BOTTOM_CENTER,autoClose:3000});
        }else if(kind === 2) {
            toast.error(message,{position:toast.POSITION.BOTTOM_CENTER,autoClose:3000});
        }
    };

    //Login functions

    loginUsernameChangeHandler = (e) => {
        const username = e.target.value;
        this.setState({usernameInput:username});
    };

    loginPasswordChangeHandler = (e) => {
        const password = e.target.value;
        this.setState({passwordInput:password});
    };

    loginNewPasswordChangeHandler = (e) => {
        this.setState({newPasswordInput:e.target.value});
    };

    loginAbbreviationChangeHandler = (e) => {
        const abbreviation = e.target.value;
        this.setState({abbreviationInput:abbreviation});
    };

    loginClickHandler = () => {
        if(this.state.usernameInput !== '' && this.state.passwordInput !== '') {
            this.login();
        }else{
            this.showMessage(2,'Bitte füllen Sie alle Felder aus');
        }
    }

    login = () => {
        const data = {
            abbreviation:this.state.abbreviationInput,
            username:this.state.usernameInput,
            password:this.state.passwordInput,
            deviceToken:localStorage.getItem("deviceToken")
        };
        api.post("/auth/login",data)
            .then(response => {
                localStorage.setItem('abbreviation',this.state.abbreviationInput);
                localStorage.setItem('username',this.state.username);
                localStorage.setItem('token',response.data.token);
                this.setState({loggedIn:true});
            })
            .catch(error => {
                if(error.response.data === "Die Zugangsdaten sind abgelaufen") {
                    this.showExpiredPasswordDialog();
                }else if(error.response) {
                    this.showMessage(2,"Unternehmenskürzel, Benutzername und Passwort stimmen nicht über ein");
                    console.log(error.response)
                } else if (error.request) {
                    this.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    fastLogin = (fastLogin) => {
        let data = {
            username: fastLogin.username,
            abbreviation: localStorage.getItem("abbreviation"),
            deviceToken: localStorage.getItem("deviceToken"),
            pin: fastLogin.pin
        }
        api.post("/auth/fast-login",data)
            .then(response => {
                this.closeFastLoginDialog();
                localStorage.setItem('abbreviation',this.state.abbreviationInput);
                localStorage.setItem('username',this.state.username);
                localStorage.setItem('token',response.data.token);
                this.setState({loggedIn:true});
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.showMessage(2,"Etwas ist schiefgelaufen");
                    this.logout();
                }
            });
    }

    registerClickHandler = () => {
        this.showMessage(2,'Nicht verfügbar')
    };

    logout = () => {
        if(true) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            const url = "/users/logout";
            return api.put(url)
                .then(
                    window.location.reload(false)
                );
        }
    }

    changeExpiredPasswordOnClickHandler = () => {
        const data = {
            abbreviation:this.state.abbreviationInput,
            username:this.state.usernameInput,
            oldPassword:this.state.passwordInput,
            newPassword:this.state.newPasswordInput
        };
        api.put("/users/expired-password",data)
            .then(response => {
                localStorage.setItem('abbreviation',this.state.abbreviationInput);
                localStorage.setItem('username',this.state.username);
                localStorage.setItem('token',response.data.token);
                this.setState({loggedIn:true});
                this.closeExpiredPasswordDialog();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.showMessage(2,"Etwas ist schiefgelaufen");
                    this.logout();
                }
                this.setLoading(false);
            });
    }

    showExpiredPasswordDialog = () => {
        this.setState({showExpiredPasswordDialog:true});
    }

    closeExpiredPasswordDialog = () => {
        this.setState({showExpiredPasswordDialog:false});
    }

    showFastLoginDialog = (fastLogin) => {
        this.setState({fastLogin:fastLogin,showFastLoginDialog:true});
    }

    closeFastLoginDialog = () => {
        this.setState({fastLogin:null,showFastLoginDialog:false});
    }

    render() {

        let content;
        if(localStorage.getItem('token') !== null) {
            //show tool
            content = <Tool
                showMessage={this.showMessage}
                username={this.state.usernameInput}
                logout={this.logout}
                setLoading={this.setLoading}
            />;
        }else{
            //show login
            content = <Login
                showMessage={this.showMessage}
                loginUsernameChangeHandler={this.loginUsernameChangeHandler}
                loginPasswordChangeHandler={this.loginPasswordChangeHandler}
                loginAbbreviationChangeHandler={this.loginAbbreviationChangeHandler}
                loginClick={this.loginClickHandler}
                registerClick={this.registerClickHandler}
                setLoading={this.setLoading}
                fastLogins={this.state.fastLogins}
                showFastLoginDialog={this.showFastLoginDialog}
                loadFastLogins={this.loadFastLogins}
            />;
        }

        return (
            <Router>
                <ExpiredPasswordDialog
                    open={this.state.showExpiredPasswordDialog}
                    close={this.closeExpiredPasswordDialog}
                    showMessage={this.showMessage}
                    loginNewPasswordChangeHandler={this.loginNewPasswordChangeHandler}
                    changeExpiredPasswordOnClickHandler={this.changeExpiredPasswordOnClickHandler}
                />

                {this.state.showFastLoginDialog ? <FastLoginDialog
                    open={this.state.showFastLoginDialog}
                    close={this.closeFastLoginDialog}
                    showMessage={this.showMessage}
                    fastLogin={this.fastLogin}
                    fastLoginRequest={this.state.fastLogin}
                /> : null}

                <div id="app" className='app'>
                    {this.state.loading ? <LoadingBackdrop/> : null}
                    <div className='app-content'>
                        {content}
                    </div>
                </div>
            </Router>
        )
    }

}

export default App;
