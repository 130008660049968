import React, {useEffect, useState} from "react";
import "./ReportingDataChart.css";

//Components
import {CartesianGrid, Line, LineChart, Tooltip, XAxis} from "recharts";
import HelperFunctions from "../../../../../../../reusable/HelperFunctions";

const ReportingDataChart = (props) => {

    const [screenWidth, setScreenWidth] = useState(document.getElementById("app").offsetWidth);

    return (
        <div>
            {screenWidth ? <LineChart
                width={screenWidth > 800 ? 640 : 480}
                height={screenWidth > 800 ? 256 : 192}
                data={props.formattedTransactions}
                className="reporting-data-chart"
            >
                <XAxis dataKey="Datum" />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" />
                <Line type="monotone" dataKey="Transaktionen" stroke="#FF4D4D" yAxisId="0" />
            </LineChart> : null}
        </div>
    )
}

export default ReportingDataChart;