import React from "react";
import './ExpiredPasswordDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../ToolButton/ToolButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import LockIcon from "@mui/icons-material/Lock";

const ExpiredPasswordDialog = (props) => {

    return (
        <Dialog scroll='body' open={props.open} onClose={props.close} className='expired-password-dialog'>
            <DialogTitle>Passwort abgelaufen</DialogTitle>
            <DialogContent>
                <CloseIcon onClick={props.close} className='icon close-icon'/>
                <p>Ihr Passwort ist abgelaufen. Bitte geben Sie ein neues Passwort ein.</p>
                <div className='input-div'>
                    <LockIcon className='input-icon'/>
                    <input className='input-field' placeholder='Neues Passwort' type='password'
                           value={props.newPasswordInput}
                           onChange={props.loginNewPasswordChangeHandler}/><br/>
                </div>
                <DialogActions>
                    <ToolButton main className='button' onClick={props.changeExpiredPasswordOnClickHandler}>Passwort
                        ändern</ToolButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ExpiredPasswordDialog;